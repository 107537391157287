<template>
  <div class="job">
    <el-row class="btns">
      <el-select v-model="selectClassId" placeholder="请选择" size="48px">
        <el-option
          v-for="item in classList"
          :label="item.class_title"
          :value="item.shop_user_class_id"
          :key="item.shop_user_class_id"
        >
        </el-option>
      </el-select>
      <el-button
        type="primary"
        class="btn creactBtn"
        plain
        @click="goToJobLibrary"
        >作业库</el-button
      >
    </el-row>
    <el-row class="classList">
      <el-col
        class="classItem"
        v-for="item in workList"
        :key="item.shop_user_class_entrepot_id"
        type="flex"
        justify="space-between"
      >
        <el-row class="itemTop" type="flex" justify="space-between">
          <el-col :span="18" class="jobName">{{ item.entrepot_name }}</el-col>
          <el-col :span="6">
            <!-- <el-row type="flex" justify="space-between" class="topIcon">
              <el-col class="iconOutline" @click.native="onEditClass(item.id)"
                ><i class="el-icon-edit-outline"></i
              ></el-col>
              <el-col
                class="iconDelete"
                @click.native="
                  openDeleteConfirm(item.shop_user_class_entrepot_id)
                "
                ><i class="el-icon-delete"></i
              ></el-col>
            </el-row> -->
          </el-col>
        </el-row>
        <div class="itemMainBox">
          <el-row class="itemMain">
            <el-col :span="13">
              <div class="classTitle">
                <el-image
                  class="mainImg"
                  :src="require(`@/assets/image/jobIcon.png`)"
                ></el-image>
                <div class="className">{{ item.class_title }}</div>
              </div>
              <div class="checkJob">
                待批改{{ item.plan_count }},{{ item.plan_all }}已交
              </div>
            </el-col>
            <el-col class="mainNumBox" :span="11">
              <div class="jobEndTime">
                截止时间：{{ conversion(item.end_time) }}
              </div>
              <el-button
                class="readOverBtn"
                type="primary"
                @click="goCorrecting(item)"
                >批阅</el-button
              >
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  getAdminClassList,
  getClassEntrepot,
  deleteClassEntrepot,
} from "@/request/api";
// import UploadModal from '@/components/UploadModal.vue'

export default {
  // components: {
  //   UploadModal
  // },
  data() {
    return {
      classList: [], // 班级列表
      workList: [], // 班级作业列表
      classForm: {}, //班级表单对象
      selectClassId: "", //选择班级
      handleCreateClassVisible: false, // 新建班级模态框是否显示
      handleAllocationClassVisible: false, // 新建班级模态框是否显示
      formLabelWidth: "100px",
      // currentClass: {}, // 当前操作的班级
    };
  },
  watch: {
    selectClassId(value) {
      this.onGetClassEntrepot(value);
    },
  },
  methods: {
    async init() {
      const res = await getAdminClassList();
      if (res.status === 200) {
        this.classList = res.data.data;
        this.selectClassId = res.data.data[0].shop_user_class_id;
      }
      // if (res.data.data[0]) {
      //   await this.onGetClassEntrepot(res.data.data[0].shop_user_class_id);
      // }
    },
    async onGetClassEntrepot(shop_user_class_id) {
      const ret = await getClassEntrepot(shop_user_class_id);
      if (ret.status === 200) {
        this.workList = ret.data.data;
      }
    },

    // 去作业库
    goToJobLibrary() {
      this.$router.push("jobLibrary");
    },
    goCorrecting({ shop_user_class_entrepot_id, shop_user_class_id }) {
      this.$router.push({
        path: "/aiClass/jobCorrecting",
        query: { shop_user_class_entrepot_id, shop_user_class_id },
      });
    },
    // 编辑班级
    onEditClass(id) {
      this.classList.forEach((item) => {
        if (item.id === id) {
          this.classForm = { ...item };
          this.handleCreateClassVisible = true;
        }
      });
    },
    openDeleteConfirm(id) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.onDeleteClass(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onDeleteClass(shop_user_class_entrepot_id) {
      deleteClassEntrepot({ shop_user_class_entrepot_id }).then((res) => {
        if (res.status === 200) {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        } else {
          this.$message({
            type: res.data.msg,
            message: "删除失败!",
          });
        }
      });
    },
    onAllocationClassSubmit() {},
    conversion(time) {
      const date = new Date(time);
      const Y = date.getFullYear();
      const M = date.getMonth() + 1;
      const D = date.getDate();
      return Y + "-" + M + "-" + D;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="less" scoped>
.job {
  .btns {
    margin: 41px 0px 41px 51px;
    .btn {
      width: 149px;
      line-height: 17px;
      font-size: 20px;
      border-radius: 10px;
    }
    /deep/ .el-input__inner {
      height: 41px;
      line-height: 41px;
      font-size: 20px;
      width: 216px;
    }
    .creactBtn {
      // background: #91d883;
      // border: 1px solid #91d883;
      margin: 0px 48px;
    }
  }
  .classList {
    // margin-left: 60px;
    .classItem {
      width: 458px;
      height: 273px;
      margin-left: 51px;
      margin-bottom: 60px;
      .itemTop {
        // height: 58px;
        border-radius: 10px 10px 0px 0px;
        background: #91d883;
        color: #fff;
        font-size: 24px;
        line-height: 48px;
      }
      .jobName {
        margin-left: 20px;
      }
      .topIcon {
        margin-right: 20px;
        line-height: 48px;
        text-align: right;
        cursor: pointer;
      }
      .itemMainBox {
        border: 1px solid #91d883;
        border-radius: 0px 0px 10px 10px;
        background: #fff;
        border-top: 0px;
        margin: 0 auto;
        padding: 26px 20px;
      }
      .itemMain {
        width: 418px;
        height: 154px;
        padding: 20px;
        background: #f2f7ff;
        border-radius: 10px 10px 10px 10px;
        .classTitle {
          display: flex;
          // text-align: left;
          // justify-content: left;
        }
        .className {
          line-height: 39px;
          margin-left: 15px;
        }
        .checkJob {
          font-size: 16px;
          margin-top: 20px;
        }
        .readOverBtn {
          width: 128px;
          line-height: 11px;
          margin-top: 20px;
          margin-left: 25px;
        }
      }
      .mainImg {
        width: 39px;
        height: 39px;
      }
      .mainNumBox {
      }
      .mainNumTitle {
        font-size: 20px;
      }
      .mainNum {
        font-size: 22px;
        color: #0066ff;
      }
    }
  }
}
</style>
